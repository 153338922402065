import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '3WflFt0AhP0k5agSAfqagd',
  name: 'home-duplicated',
  blocks: [
    {
      id: '1m9gjLRyX1AIgQjryKajKk',
      type: 'Carousel',
      props: {
        technicalName: 'Carousel - JAPAN',
        slides: [
          {
            id: '2Wg7DWzcUBs50bmr37rQ11',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5OG9nRdqk4AWegW4CohwUw/b6d1a8db0e0097c82ae59478615ec34c/BM021_HP_Banner_MacBook-1.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3jVvGSodmdDF7ogIxWtJFT/546c56b97e90abea8b2f13dd547847d3/BM021_HP_Banner_MacBook-2.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: '[JP] [HP] Macbook Oct Promo Code',
            },
            link: {
              href: 'https://www.backmarket.co.jp/ja-jp/l/macbook/831925a6-4741-4dca-aef4-be15604cb0e8',
            },
          },
          {
            id: '70jotnYMoW1tBxSiAShlSo',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7syc9z5obnrWKZrz509ChK/9979462638493b97bf9e62fb55e195ee/_iPhone14Sale_Desktop.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4OwyKmXk8bGIA55m8vLdDm/9f7a51da17194709cf401a88b191cc3e/_iPhone14Sale_Mobile.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: '[JP] [HP] iPhone 14',
            },
            link: {
              href: 'https://www.backmarket.co.jp/ja-jp/l/iphone-14/2385e855-293b-400d-90c3-6708e883eeac',
            },
          },
          {
            id: '2WzRd9WtY1J3NZvXbIlmEZ',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6RCDpn4tn5PDoxuM4qIsY4/5780686e60494bea3b5dd6e0a406ed62/BM015_HomeBanner_RefurbishedvsUsed_Desktop__1_.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6q6sKCYC7roJGmO7riwY3H/93bda627b2655a62539e7d130a0b38cb/BM015_HomeBanner_RefurbishedvsUsed_Mobile.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'JP - Refurbished vs Used 2024',
            },
            link: {
              href: 'https://www.backmarket.jp/ja-jp/c/news/refurbished-vs-secondhand',
            },
          },
          {
            id: '2NwxR6XE2szHGfrST5IKQW',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1jJGJLBIqE84YqG9WSbtB4/134e0a8c01059e326cc78d2cfe3d19fd/BM015_HomeBanner_PlusCare_DeskTop_v05__1_.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1QsBQZGT2lxKPtmEDgYyjj/7bcb0cc57ea6a6d06f6ad0db1575b8c2/BM015_HomeBanner_PlusCare_Mobile_v06.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'JP - Back up 2024',
            },
            link: {
              href: 'https://www.backmarket.co.jp/ja-jp/e/backup?srsltid=AfmBOootg_5zx9M_WVSXCBNU0VgW8ZeUgZV31aubF9l2yktAwjDY70ON',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '42p4EnfCHxVpKykV9pBqDn',
      type: 'Media',
      props: {
        technicalName: '[JP] Reassurance',
        image: {
          src: 'https://images.ctfassets.net/mmeshd7gafk1/dOBFWE1LtrlNkW8kdAVOz/a8605887ac5f7231ebce33fd54077ce5/d_reassurance.png',
          width: 2890,
          height: 1329,
          srcMobile:
            'https://images.ctfassets.net/mmeshd7gafk1/2VG2bbBXuCAQigvoL8fJZe/05df1b653f7020e66dafb81ade5bc635/m_reassurance.png',
          widthMobile: 660,
          heightMobile: 2960,
          alt: '',
        },
      },
      hiddenDevices: [],
    },
    {
      id: '4yzYG8NODJoMNC1wb5KlJL',
      type: 'PressCarousel',
      props: {
        technicalName: 'JP Press Carousel',
        title: 'メディア紹介実績',
        subtitle: '多くのメディアで取り上げていただきました。',
        articles: [
          {
            id: '1wvraIXZBiOs8dBh9ZKcZP',
            text: 'Back Marketの「リファービッシュ製品」が中古と違うワケ　売れ筋はiPhone 13、バッテリー“100％保証”の計画も',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/50IQLwM0oebRQWXGh0hD0i/44d94e3bb7635413deb0c3fd55e64d5d/media_mobile.png',
              width: 225,
              height: 110,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/40njko2f1Vms432bO22KAW/aa98a182631c4aed3fd9707475247b7d/media_mobile.png',
              widthMobile: 225,
              heightMobile: 110,
              alt: 'Media Mobile',
            },
            link: {
              href: 'https://www.itmedia.co.jp/mobile/articles/2404/17/news122.html',
            },
            publicationDate: '2024/04',
          },
          {
            id: '651lfdUsVIqBp6POifAfzx',
            text: ' 電子機器の「リファービッシュ（整備済製品）」市場をつくる、仏発・新興企業のマーケティング戦略',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1esAxIk3tafbDjftxbmZmT/8293d50f9a47cde6865281fd5ec2d8a0/adver_times_logo.png',
              width: 471,
              height: 122,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6P4bM8DVMiB6e6S763yBa6/240a99f3122d12361834756204b25722/adver_times_logo.png',
              widthMobile: 471,
              heightMobile: 122,
              alt: 'Logo',
            },
            link: {
              href: 'https://www.advertimes.com/20240131/article443339/',
            },
            publicationDate: '2024/01',
          },
          {
            id: 'oCmFp4BTpp34q5e3TXfJS',
            text: ' フランスのユニコーンが日本進出 電子ゴミを減らして循環型社会に',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6qs9q8qky0ZSauDHvOq4xG/6ee34282405fdedd2c28056077c96f63/pdo.png',
              width: 104,
              height: 82,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3foxRmF4wWgmdFiZOv3pE7/794d71e5edaa23e679345c013ea4eed9/pdo.png',
              widthMobile: 104,
              heightMobile: 82,
              alt: 'Project design online',
            },
            link: {
              href: 'https://www.projectdesign.jp/articles/389da907-a159-4509-ba8e-1b411b4ff224',
            },
            publicationDate: '2023/12',
          },
          {
            id: '5Uk2ICM0ml54ft3T1MDMMs',
            text: '整備済み製品を第一の選択肢へ--フランス発、リファービッシュEC「Back Market」の取り組み',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/zAWPTCXOudPzwIEqf9uhf/d4d6af0bd918d191e5aeef0456d73164/logo_cnetjapan_2022.png',
              width: 156,
              height: 22,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1REFRCm71qj9PwkcsiyM2i/515afde0a13017591662bde0b9eff8ac/logo_cnetjapan_2022.png',
              widthMobile: 156,
              heightMobile: 22,
              alt: 'CNET JAPAN',
            },
            link: {
              href: 'https://japan.cnet.com/article/35202882/',
            },
            publicationDate: '2023/04',
          },
          {
            id: '2gvebxVxcNIO5LaVwqqYD',
            text: '旧型iPhoneこそクール　資源を生かし切る経営とは 本社コメンテーター　村山恵一',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5e04cUCOhU8W74eZzde176/de579f03f4ea3a86d51873e3cbe06a84/294px-Nikkei_logo_ja.svg.png',
              width: 294,
              height: 35,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4fRlyb9jsxONBF0RQgukOC/c2ca02773860584637e5252a1e83553e/294px-Nikkei_logo_ja.svg.png',
              widthMobile: 294,
              heightMobile: 35,
              alt: 'Nikkei',
            },
            link: {
              href: 'https://www.nikkei.com/article/DGXZQOCD06CEY0W2A700C2000000/',
            },
            publicationDate: '2022/07',
          },
          {
            id: '67lCzdPpCg6sSIxiLP6hbq',
            text: 'なぜベンチャーキャピタルは、サーキュラーエコノミーに投資するのか',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6qE9DssomnJhqMdPrM6rLj/897f564990f786b78578ef8ad14451dc/Forbes-JAPAN_logo.jpg',
              width: 830,
              height: 510,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1aDwsqo5TofBhrvdWmnsYk/ec8de6a1abefb5fb97bdb38ebc315160/Forbes-JAPAN_logo.jpg',
              widthMobile: 830,
              heightMobile: 510,
              alt: 'Japan Forbes',
            },
            link: {
              href: 'https://forbesjapan.com/articles/detail/49023/page5',
            },
            publicationDate: '2022/07',
          },
          {
            id: '3bNwvrG3ohXWDlFDSptxcU',
            text: '中古スマホから家電まで　電子機器もサステイナブルに再生・流通 　リファービッシュ専門マーケットプレイスBack Market',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1V6RFc1aQ6jg6NNwrdESh4/cf1ec39ec4a717e210ce9ddbfbcce6ac/71955730_993958210935460_3456225943335469056_n.jpg',
              width: 800,
              height: 300,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/KkU4bHMexHCh1SLFGTMQc/4a38e47286c30776e459a94ab02ae3d5/71955730_993958210935460_3456225943335469056_n.jpg',
              widthMobile: 800,
              heightMobile: 300,
              alt: 'Techblitz',
            },
            link: {
              href: 'https://techblitz.com/back-market/',
            },
            publicationDate: '2022/05',
          },
          {
            id: '7mEvedXtKVGeA2jbN64ZUQ',
            text: '欧州で中古スマホ販売を拡大するフランスのベンチャーが日本市場に本腰、2023年は前年比3倍が目標',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/20dbW2dSlKtDdfqS1B8U2J/db288821c935855442523291c5c63219/t__l__chargement.png',
              width: 355,
              height: 142,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2SsRwa6KAahCmXD9YTqB86/4ad5479b140b4125015c28373a0189f4/t__l__chargement.png',
              widthMobile: 355,
              heightMobile: 142,
              alt: 'Ascii.jp',
            },
            link: {
              href: 'https://ascii.jp/elem/000/004/101/4101548/',
            },
            publicationDate: '2022/08',
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: 'HzsXdFb591xMpUx92Bs5Z',
      type: 'Categories',
      props: {
        technicalName: 'Most wanted tiles',
        title: 'いま注目のカテゴリー',
        subtitle: 'よく見られている人気商品',
        categories: [
          {
            id: '4vRkng0byTg9NIWBdo0Bqk',
            title: 'iPhone',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2wB6Q7zrqf5uoLpXzxPEel/f9ad959ed631feadc64dc273ec35b397/iPhone_12_Pro_Max.jpg',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/7KdxR2oMxnX4oZr1XGp7cX/a029dcd2252e2892166c3aaf68e6907e/iPhone_12_Pro_Max.jpg',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'iPhone 12',
            },
            tagline: '最新機種もお得なモデルも',
            link: {
              href: 'https://www.backmarket.co.jp/ja-jp/l/iphone/fe399560-2b82-4503-804b-7d41fbcc5d96',
            },
          },
          {
            id: '30Pd4hTebBm5g1TMSAzsgW',
            title: 'iPad',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6hLh91DeuCqtkidZXUD4li/73c2b0f8d24d36b135eb3e9303ffd8fd/iPad_Pro_11_2020__1_.png',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/8GLfGBhJVObAmjsszIDJC/2eacd0a1d8d8a415e76664e5af1298dd/iPad_Pro_11_2020.png',
              widthMobile: 400,
              heightMobile: 400,
              alt: 'iPad Pro',
            },
            tagline: 'タブレットも豊富に',
            link: {
              href: 'https://www.backmarket.co.jp/ja-jp/l/ipad/1692e8f6-4671-4e3a-a99b-8b18841e6f35',
            },
          },
          {
            id: '6ZeaNs9ajX747cHNDZjyYj',
            title: 'MacBook',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/KQmrFTTS0O11Ck8Iw0CNs/85c11b6bdb04b1649a7f6cc8c370e41d/MacBook_M1__2_.png',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4LIOX7UHPh1TkHcPycmSl3/33d3c529f8167cf8fcabbc2890197be2/MacBook_M1__3_.png',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'MacBook M1',
            },
            tagline: 'M1からM3までラインナップ',
            link: {
              href: 'https://www.backmarket.co.jp/ja-jp/l/macbook/831925a6-4741-4dca-aef4-be15604cb0e8',
            },
          },
          {
            id: '1BENU9JxdsKG7yhN9SXDep',
            title: 'Apple Watch',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3uCwEhg7CFIyIsBaUlkxyt/09c9327b3e07ee1eabd21ffb53a6c840/Apple_Watch_Series_7.png',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3X07g8cRpO4ZgKTWtdt0Xn/d1dae8efe9b05ca0b40398799ad2e63b/Apple_Watch_Series_7__1_.png',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'Apple Watch Series 7',
            },
            tagline: '新しい習慣をお得にスタート',
            link: {
              href: 'https://www.backmarket.co.jp/ja-jp/l/apple-watch/ce7db2e5-e878-47d8-99f4-f81f60383ead',
            },
          },
          {
            id: '4liNCIaKDJP9kOYKqTIdB2',
            title: 'AirPods & イヤホン',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/016a4KgrM7mhr3WwZBluoZ/2d020c900358c38aafc696e2cdcc435f/308532_7b427385-34fc-40f5-9e68-73890ed77684-thumb__1_-removebg-preview.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Airpods Pro',
            },
            tagline: 'アクセサリーもお得に',
            link: {
              href: 'https://www.backmarket.co.jp/ja-jp/l/airpods/da21cbdb-467d-4d90-aaef-26afbb84e7b4',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '52Y5MALuJRoXC0m8Q7p1Eu',
      type: 'Categories',
      props: {
        technicalName: 'Latest Popular Items',
        title: '最新の人気商品',
        subtitle: 'いま売れている機種',
        categories: [
          {
            id: '1TUpXWDpARIfBqxVMQFmqu',
            title: 'iPhone 13 Pro',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3zoZJs9jnGFyk9TrqjPmvy/ca6b7cfa2dfbc8443ebc2a84c50432ff/Capture-removebg-preview__4_.png',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2Ykzyj60ildhj1a3xqqmUf/b69b06e623214ca9347bd9a174f2c15c/Capture-removebg-preview__4___1_.png',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'iPhone 13 Pro',
            },
            tagline: '高性能をお得に',
            link: {
              href: 'https://www.backmarket.co.jp/ja-jp/l/iphone-13-pro/ebd9ec44-eaee-4339-b446-a8e71f10808e',
            },
          },
          {
            id: '4aKw25GZ82NbNNZ8GyeRL9',
            title: 'iPhone 12',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/484FIbXtVdwTTr474DNMgS/5b43a5ff692a20df54bc8d6407b42c2d/iphone_12.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPhone 12',
            },
            tagline: '安心の商品コンディション',
            link: {
              href: 'https://www.backmarket.co.jp/ja-jp/l/iphone-12/d592825d-a581-4b8b-af49-7b30356ef7f5',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '4jriK2vdmm1jnYxPlAbA26',
      type: 'ContentMedia',
      props: {
        technicalName: 'B Corp - desktop',
        contentTitle: '私たちはB-Corp認証企業です',
        text: 'B-Corp認証とは、サスティナブルな活動を実践している企業に与えられる認証です。商品やサービスはもちろん、採用活動など、あらゆる企業活動で環境への影響を考慮していることが求められます。',
        textPosition: 'right',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.co.jp/ja-jp/c/news/bcorp-status',
          },
          label: 'もっと読む',
        },
        image: {
          src: 'https://images.ctfassets.net/mmeshd7gafk1/4lxMWc7fDyHmBhxo6TY2q1/595a3faec177dbc04cc018754eee226a/bcorp_content_desktop.webp',
          width: 960,
          height: 824,
          srcMobile:
            'https://images.ctfassets.net/mmeshd7gafk1/2sDB6miLQlDl97B2jvasta/bd97c34355d7b9ec7d3167bac58c4077/B_corp_mobile.webp',
          widthMobile: 327,
          heightMobile: 412,
          alt: 'B Corp logo with a green background - JP',
        },
      },
      hiddenDevices: ['mobile'],
    },
    {
      id: '7HyNyXdBAxMbb5iiLOBQXg',
      type: 'EmailCollector',
      props: {
        title: '最新の整備済みスマートフォンをお探しですか？',
        text: '登録しておトクなニュースやお役立ち情報を受け取りましょう。',
        buttonLabel: '登録する',
        checkboxLabel:
          'バックマーケットからのメールを受け取ることに同意します。',
      },
      hiddenDevices: [],
    },
    {
      id: '34b7qEXvdwRd6HB2UqGQfi',
      type: 'Faq',
      props: {
        technicalName: 'FAQ',
        title: 'Back Marketをもっと知る',
        subtitle: 'よくある質問をまとめました',
        questions: [
          {
            id: '6gIGc8B9ts5WIL9RpqMvcq',
            title: '安心して購入できる理由は？',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Back Marketでご購入いただいた商品は\nすべて1年間の動作保証付きです。商品お届け日の翌日から30日以内であれば、\n送料はBack Market負担にてご返品いただけます。',
                      marks: [],
                      data: {},
                    },
                  ],
                },
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'また、すべての販売者は厳格な審査プロセスをクリアすることを義務付けられており\n無作為抽出により「ミステリーオーダー（技術スタッフが匿名で購入）」も実施しています。',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '5XEaSV2sKUSGl06EAAyPym',
            title: 'リファービッシュ品（整備済製品）と新品の違いは？',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'リファービッシュ品（整備済製品）は、外観だけでなく性能も新品同様です。しかも環境負荷は、新品と比べて大幅に軽減できます。スマートフォン、タブレット、ノートパソコン、その他、すべての商品ラインナップをご用意しています。',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '4jnLHMJw7rSHVI4pXcHpje',
            title: 'さらに環境への影響を減らすには？',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        '地球環境を守る方法は一つではありません。テック製品に関して言えば、Back Marketではスマートフォンはもちろん、スマートウォッチやヘッドフォンなど、多彩なリファービッシュ品（整備済製品）を取り扱っています。ぜひ、環境負荷の軽減つながるBack Marketをご利用ください。',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'HTML_ONLY',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.co.jp/ja-jp/help',
          },
          label: 'FAQをもっと見る',
        },
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'バックマーケット - お得に整備品(リファービッシュ品)を購入しよう',
  meta: {
    title:
      'バックマーケット | 整備済スマホ・タブレット・中古再生品・リファービッシュ品専門マーケットプレイス',
    description:
      'iPhone、iPad、MacBookなどのリファービッシュ品、整備済み中古スマホ・タブレットを格安で見つけるならバックマーケット! 新品の価格より最大70%割引、中古でも整備済みだから信頼できる高品質。送料無料、12ヶ月保証、30日間返品可能。',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/06e1333512728a4b72f53d601f699744/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo:
    'バックマーケット | 整備済スマホ・タブレット・中古再生品・リファービッシュ品専門マーケットプレイス',
  titleBreadcrumb: 'ホーム',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home-duplicated',
}

export default HOME_FALLBACK_DATA
